import { Link } from 'react-router-dom';
import logo from '../../assets/icpm-logo.png'
import translate from '../../assets/translate.svg'
import { Dropdown } from 'react-bootstrap';

const Header = () => {

    const changeLanguage = (lng) => {
    };

    return <div className='header'>
        <div className='container'>
            <div className='navbar'>
                <Link to='/'>
                    <img src={logo} alt='logo' className='logo'/>
                </Link>
                <div>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className='p-0' style={{background: 'none', border: 'none'}}>
                    <img src={translate} alt='translate' width={27} className='mx-1' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item style={{color: '#000'}} onClick={()=> changeLanguage('en')}>
                        English
                        </Dropdown.Item>
                        <Dropdown.Item style={{color: '#000'}} onClick={()=> changeLanguage('ar')}>
                        اللغه العربيه
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    </div>
}
export default Header;