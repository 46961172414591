import { Carousel } from 'react-bootstrap';
import banner1 from '../../assets/banner.jpeg'
import banner2 from '../../assets/banner1.jpeg'
import banner3 from '../../assets/banner2.jpeg'
import { Link } from 'react-router-dom';
import category1 from '../../assets/7.jpeg'
import category2 from '../../assets/8.jpg'

export const Home = () => {
    const data = [banner1, banner2, banner3]
    return<>
        <div>
            <div className='container'>
                <Carousel>
                    {data?.map((banner, index) => {
                        return <Carousel.Item key={index} interval={2500}>
                        <img src={banner} alt='banner' className='w-100 h-100' />
                    </Carousel.Item>
                    })}
                </Carousel>
            </div>
        <div className='home-cat my-5 container'>
            <h3 className='title'>Categories</h3>
            <div className='mt-4'>
                <div className='row'>
                    <div className='col-2'></div>
                    <div className='col-md-4 col-12 mb-3 text-center'>
                        <div className='category-div'>
                            <Link to='/category/ais' className='category'>
                                <img src={category2} alt='category' className='w-100'/>
                                <h4 className='mt-3'>Gps + AIS B</h4>
                            </Link>
                        </div>
                    </div>
                    <div className='col-md-4 col-12 mb-3 text-center'>
                        <div className='category-div'>
                            <Link to='/category/radio' className='category'>
                                <img src={category1} alt='category' className='w-100'/>
                                <h4 className='mt-3'>Radio</h4>
                            </Link>
                        </div>
                    </div>
                    <div className='col-2'></div>
                </div>
            </div>
        </div>
        </div>
    </>
};