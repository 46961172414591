import { Routes, Route } from "react-router-dom";
import Header from './pages/Header';
import './App.css';
import { Home } from "./pages/Home";
import Category from "./pages/Category";
import Footer from "./pages/Footer";
import ScrollToTop from "./common/ScrollToTop";

function App() {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/category/:id' element={<Category />} />
        <Route path='*' element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
