import { useLocation } from 'react-router-dom'
import insta from '../../assets/insta.png'
import tiktok from '../../assets/tiktok.png'
import snap from '../../assets/snap.png'
import { useEffect, useState } from 'react'
function FooterContent() {
	const [type, setType] = useState('')
	const location = useLocation()
    
    useEffect(()=>{
        let cate = location?.pathname?.split('/category')[1]
        setType(cate)
    }, [location])

	return (
		<div className="aximo-default-content light position-relative">
			<div className="aximo-info-wrap">
				{(type !== '/ais' && type !== '/radio') && <div className="aximo-info mb-4">
					<ul>
						<li>Contact Us: لتواصل والإستفسار</li>
						<li>
							<a href="tel:94407242">94407242</a>
						</li>
						<li className='d-flex' style={{gap: '12px'}}>
							<a href="https://snapchat.com/t/J7tGobq4" target='_blank' rel="noreferrer">
								<img src={snap} alt='insta' width={26} height={26} />
							</a>
							<a href="https://www.tiktok.com/@icom4sale?_t=8pYerTNEjFH&_r=1" target='_blank' rel="noreferrer">
								<img src={tiktok} alt='insta' width={26} height={26} />
							</a>
							<a href="https://www.instagram.com/icom4sale?igsh=OXh1NnB0OW9tcnlu" target='_blank' rel="noreferrer">
								<img src={insta} alt='insta' width={26} height={26} />
							</a>
						</li>
					</ul>
				</div>}
				{type === '/ais' && <div className="aximo-info mb-4">
					<ul>
						<li>Ais B: لتواصل والإستفسار</li>
						<li>
							<a href="tel:55250502">55250502 </a>
						</li>
						<li>
							<a href="https://www.instagram.com/deltafirst_?igsh=bTdxamt3cWNsN2h2" target='_blank' rel="noreferrer">
								<img src={insta} alt='insta' width={26} height={26} />
							</a>
						</li>
					</ul>
				</div>}
				{type === '/radio' && <div className="aximo-info mb-4">
					<ul>
						<li>Radio/GPs+AiS: لتواصل والإستفسار</li>
						<li>
							<a href="tel:94407242">94407242</a>
						</li>
						<li>
							<a href="https://snapchat.com/t/J7tGobq4" target='_blank' rel="noreferrer">
								<img src={snap} alt='insta' width={26} height={26} />
							</a>
							<a href="https://www.tiktok.com/@icom4sale?_t=8pYerTNEjFH&_r=1" target='_blank' rel="noreferrer">
								<img src={tiktok} alt='insta' width={26} height={26} />
							</a>
							<a href="https://www.instagram.com/icom4sale?igsh=OXh1NnB0OW9tcnlu" target='_blank' rel="noreferrer">
								<img src={insta} alt='insta' width={26} height={26} />
							</a>
						</li>
					</ul>
				</div>}
			</div>
			{/* <div className="aximo-social-icon social-large">
				<ul>
					<li>
						<a href="https://twitter.com/" target="_blank">
							<i className="icon-twitter"></i>
						</a>
					</li>
					<li>
						<a href="https://facebook.com/" target="_blank">
							<i className="icon-facebook"></i>
						</a>
					</li>
					<li>
						<a href="https://www.instagram.com/" target="_blank">
							<i className="icon-instagram"></i>
						</a>
					</li>
					<li>
						<a href="https://www.linkedin.com/" target="_blank">
							<i className="icon-linkedin"></i>
						</a>
					</li>
				</ul>
			</div> */}
			{/* <div className="aximo-hero-shape aximo-footer-shape">
				<img src={Shape1Img} alt="Shape1Img" />
			</div> */}
		</div>
	);
}

export default FooterContent;
