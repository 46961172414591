import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import ImageModal from "./ImageModal"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './style.css'

import antenna1 from '../../assets/Antenna/1.jpg'
import antenna2 from '../../assets/Antenna/2.jpg'
import antenna3 from '../../assets/Antenna/3.jpg'
import antenna4 from '../../assets/Antenna/4.jpg'
import antenna5 from '../../assets/Antenna/5.jpg'

import bft32 from '../../assets/BF-T3/2.jpg'
import bft33 from '../../assets/BF-T3/3.jpg'
import bft36 from '../../assets/BF-T3/6.jpg'
import bft37 from '../../assets/BF-T3/7.jpg'

import icomIc1 from '../../assets/ICOM IC-2730E/1.jpg'
import icomIc2 from '../../assets/ICOM IC-2730E/2.jpg'
import icomIc3 from '../../assets/ICOM IC-2730E/3.jpg'
import icomIc4 from '../../assets/ICOM IC-2730E/4.jpg'

import tyt1 from '../../assets/TYTUV/1.jpg'
import tyt2 from '../../assets/TYTUV/2.jpg'
import tyt3 from '../../assets/TYTUV/3.jpg'
import tyt4 from '../../assets/TYTUV/4.jpg'
import tyt14 from '../../assets/TYTUV/14.jpg'
import tyt15 from '../../assets/TYTUV/15.jpg'
import tyt18 from '../../assets/TYTUV/18.jpg'

import magnetic2 from '../../assets/magnetic/2.jpg'
import magnetic4 from '../../assets/magnetic/4.jpg'

import walkie1 from '../../assets/walkie/1.jpeg'
import walkie2 from '../../assets/walkie/2.jpeg'
import walkie3 from '../../assets/walkie/3.jpeg'
import walkie4 from '../../assets/walkie/4.jpeg'
import walkie5 from '../../assets/walkie/5.jpg'

import kp1 from '../../assets/KP-25/Untitled-1.jpg'

import kp272 from '../../assets/KP-27/Untitled-2.jpg'

import ks2 from '../../assets/ks/8.jpg'

import ic35001 from '../../assets/ICOM-IC-3500/1.jpg'
import ic35002 from '../../assets/ICOM-IC-3500/2.jpg'
import ic35007 from '../../assets/ICOM-IC-3500/7.jpg'
import ic35008 from '../../assets/ICOM-IC-3500/8.jpg'

import Comnt1 from '../../assets/Comnt/01.jpg'
import Comnt2 from '../../assets/Comnt/02.jpg'
import Comnt3 from '../../assets/Comnt/03.jpg'
import Comnt4 from '../../assets/Comnt/04.jpg'
import Comnt5 from '../../assets/Comnt/05.jpg'

const Category = () => {
    const radioTabs = [
        {label: 'ICOM IC-3500', images: [ic35001, ic35002, ic35007, ic35008]},
        {label: 'ICOM IC-2730E', images: [icomIc1, icomIc2, icomIc3, icomIc4]},
        {label: 'TYT UV 99', images: [tyt1, tyt2, tyt3, tyt4, tyt14, tyt15, tyt18]},
        {label: 'BF-T3', images: [bft32, bft33, bft36, bft37]},
        {label: 'Motorolla Walkie Talkie', images: [walkie1, walkie2, walkie3, walkie4, walkie5]},
        {label: 'Antenna ICom', images: [antenna1, antenna2, antenna3, antenna4, antenna5]},
        {label: 'Antenna Comnt', images: [Comnt1, Comnt2, Comnt3, Comnt4, Comnt5]},
        {label: 'Magnetic stand', images: [magnetic2, magnetic4]}, //magnetic1 magnetic3,
    ]
    const aisTabs = [
        {label: 'Kp-25', images: [kp1]},
        {label: 'Kp-27', images: [kp272]},
        {label: 'Ks-200A', images: [ks2]},
    ]
    const [tabs, setTabs] = useState([])
    const [selectTab, setSelectTab] = useState({})
    const [modal, setModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')

    const location = useLocation()
    
    useEffect(()=>{
        let cate = location?.pathname?.split('/category')[1]

        if(cate  === '/ais'){
            setTabs([...aisTabs])
            setSelectTab(aisTabs[0])
        } else {
            setTabs([...radioTabs])
            setSelectTab(radioTabs[0])
        }
    }, [])

    return <div className="container">
    <div className="tabs">
    <Swiper
        modules={[]}
        slidesPerView={window?.innerWidth > 1024 ? 7.25 : window?.innerWidth > 768 ? 6.25 : 2.75 }
        spaceBetween={10}
        pagination={{
          clickable: true
        }}
        breakpoints={{
            1024: {
              slidesPerView: 7.25,
            },
            768: {
              slidesPerView: 6.25,
            },
            640: {
                slidesPerView: 4.25,
            },
        }}
        className="mySwiper"
      >
        {tabs?.map((tab,index)=>{
          return <SwiperSlide key={index}>
            <p onClick={()=> setSelectTab(tab)}
                className={`${tab?.label === selectTab?.label ? 'active tab' : 'tab'}`}
            >{tab?.label}</p>
            </SwiperSlide>
        })}
      </Swiper>
    </div>
    <div className="tab-section mt-5">
        <div className="row">
        {selectTab?.images?.map((img, index) => {
            return <div className="col-md-3 col-4 mb-3" key={index}>
                <img src={img} onClick={()=> {
                    setModal(true)
                    setSelectedImage(img)
                }} alt='antenna' 
                />
            </div>
        })}
        </div>
    </div>
    
    <div className="mt-5 description">
        {selectTab?.label === 'Antenna ICom' && <div>
            <p>FREQUENCY/144 ~ 170MHZ</p>
            <p>GAIN 3.9 dB</p>
            <p>MAXIMUMPOWER 200WATTS</p>
            <p>LENGTH 1.25m</p>
        </div>}
        {selectTab?.label === 'BF-T3' && <div>
            <p>كل قناة تحتوي على 38 رمز خصوصية</p>
            <p>10 نغمات اتصال</p>
            <p>شاشة</p>
            <p>سكولش تقائي</p>
            <p>نغمات لوحة المفاتيح</p>
            <p>قفل لوحة المفاتيح</p>
            <p>مصباح يدوي Built in</p>
            <p>توفير البطارية</p>
            <p>تنبيه بطارية منخفضة مسموع</p>
            <p>اتصال VOX بدون استخدام اليدين دون الحاجة إلي ملحق صوتي</p>
        </div>}
        {selectTab?.label === 'Icom 8000' && <div>
            <p>تردد 174-136 MHZ</p>
            <p>قوة الإرسال 80 واط</p>
        </div>}
        {selectTab?.label === 'ICOM IC-2730E' && <div>
            <p>COM IC-2730E</p>
            <p>VHF/UHF ديول باند</p>
            <p>قوة اإلرسال 50 واط VHF و 40 واط UHF</p>
            <p>شاشة منفصلة توفر اإلضاءة الخلفية البيضاء وضوحا ً اعلي .</p>
        </div>}
        {selectTab?.label === 'ICOM IC-3500' && <div>
            <p>جهاز ICOM IC-3500 الجديد الذي يتميز بميزات محسّنة وتصميم متين وسهل االستخدام .</p>
            <p>تردد (136 - 174) MHZ</p>
            <p> قوة اإلرسال 56 واط ( إمكانية اختيار عالي / متوسط / منخفض )</p>
            <p>شاشة LCD البيضاء ذات اإلضاءة الخلفية بتصميم واسع يعزز قابلية القراءة ويقلل من إجهاد العين.</p>
            <p>استمتع مع ميزة Audio Volume Boost وميزة صوت محسن عالي وواضح بقوة 5 وات نظام المدمج. تم زيادة فتحة السماعه ليصبح الصوت مسموعًا بوضوح وسهل السماع.</p>
            <p>إمكانية كتم الصوت العالي فوراً بلمسة واحده مع إمكانية تغيير مستوى الصوت من مستوى محدد إلى مستوى الصوت على الصفر.</p>
        </div>}
        {selectTab?.label === 'TYT UV 99' && <div>
            <ul>
                <li>TYT UV 99 Dual Band Two Way Radio</li>
                <li>136– 174MHz</li>
                <li>400-470MHz</li>
                <li>200-260MHz</li>
                <li>10واط</li>
                <li>ضد الماء</li>
                <li>شاشة LCD بتقنية DOT-MATRIX</li>
                <li>التنقل واختيار A/B</li>
                <li>Wide/Medium/Narrow Band Selectable</li>
                <li>راديو FM وذاكره 24 محطةً</li>
                <li>وضع تشغيل قائمة االختصارات</li>
                <li>تنبيه الطوارئ</li>
                <li>وظيفة ANI</li>
                <li>ترميز وفك تشفير DTMF</li>
                <li>قفل المفاتيح</li>
                <li>وضع العرض المتعدد</li>
                <li>Dual Band, Dual Standby, Dual Standby</li>
                <li>عدد القنوات 200</li>
                <li>موفر البطارية</li>
                <li>مجموعات التشويش</li>
            </ul>
        </div>}
        {selectTab?.label === 'Magnetic stand' && <div>
            <p></p>
        </div>}
        {selectTab?.label === 'Antenna Comnt' && <div>
            <ul>
                <li>تردد 144 MHZ</li>
                <li>3 dBI</li>
                <li>Length 0.51 m</li>
                <li>200 W</li>
            </ul>
        </div>}
        {selectTab?.label === 'Motorolla Walkie Talkie' && <div>
            <p>تردد UHF</p>
            <p>16 قناة</p>
            <p>ميزة الشاحن Type c</p>
            <p>صوت واضح وعالي</p>
            <p>اإلرسال إلى مسافات بعيده في المنطقه المفتوحه</p>
            <p>Frequency range: UHF 400-470MHz</p>
            <p>channels: 16</p>
            <p>Operation voltage: 3.7V DC</p>
            <p>power: -5W</p>
        </div>}

        {selectTab?.label === 'Ks-200A' && <div>
            <p>إنذارات عند الوصول إلى النطاق السفلي المحدد</p>
            <p>نظام تشغيل بسيط ومريح وسريــــــع الإسنجابه</p>
            <p>نظـــــــام التتبع eXactTrax</p>
            <p>مع تسجيـــــل مســــــارات AIS</p>
            <p>متوافــــق مع خرايــط: K-Chart 2.0/3.0</p>
            <p>أجهزة قياس الأعماق</p>
            <p>يصدر إشارة عند غرق السفنية بالمكان الذي غرقت فيه</p>
            <p>نظام تشغيل بسيط ومريح وسريع الإسنجابه</p>
        </div>}
        {selectTab?.label === 'Kp-27' && <div>
            <p>سلسلة أجهزة KP-25/27 من ONWA</p>
            <ul>
                <li>AIS Classic B</li>
                <li>نظام GPS</li>
                <li>نظام التتبع eXactTrax مع تسجيل مسارات AIS</li>
                <li></li>
            </ul>
            <p>متوافق مع خرايط :</p>
            <ul>
                <li>K-Chart 2.0/3.0</li>
            </ul>
            <p>يدعم :</p>
            <ul>
                <li>Navionics</li>
                <li>C-MAP MAX</li>
            </ul>
            <p>شاشة LCD وواجهة NMWA 0183</p>
            <p>مع عدة خيارات ( النهاري / الليلي )</p>
            <ul>
                <li>CPA/TCPA Alarm</li>
                <li>support NMEA 0183/ 2000 connections</li>
                <li>SOTDMA Technology-Same technology Class A (Guaranteed time location)</li>
                <li>W Transmit Power Increased AlS reception range allowing 5 global tracking</li>
                <li>Increased transmission rate (based on speed)</li>
                <li>exactTrax tracking</li>
            </ul>
            <p>Support:</p>
            <ul>
                <li>K-Chart 2.0/3.0</li>
                <li>Navionics</li>
                <li>C-MAP MAX</li>
            </ul>
            <p>W Transmission Power–increases the range and AlS 5</p>
            <p>Satellite reception enabling Global tracking</p>
            <p>Increased Transmission Rate (Based on speed)</p>
            <p>exactTrax™️: exactTrax Tracking</p>
        </div>}
        {selectTab?.label === 'Kp-25' && <div>
            <p>سلسلة أجهزة KP-25/27 من ONWA</p>
            <ul>
                <li>AIS Classic B</li>
                <li>نظام GPS</li>
                <li>نظام التتبع eXactTrax مع تسجيل مسارات AIS</li>
                <li></li>
            </ul>
            <p>متوافق مع خرايط :</p>
            <ul>
                <li>K-Chart 2.0/3.0</li>
            </ul>
            <p>يدعم :</p>
            <ul>
                <li>Navionics</li>
                <li>C-MAP MAX</li>
            </ul>
            <p>شاشة LCD وواجهة NMWA 0183</p>
            <p>مع عدة خيارات ( النهاري / الليلي )</p>
            <ul>
                <li>CPA/TCPA Alarm</li>
                <li>support NMEA 0183/ 2000 connections</li>
                <li>SOTDMA Technology-Same technology Class A (Guaranteed time location)</li>
                <li>W Transmit Power Increased AlS reception range allowing 5 global tracking</li>
                <li>Increased transmission rate (based on speed)</li>
                <li>exactTrax tracking</li>
            </ul>
            <p>Support:</p>
            <ul>
                <li>K-Chart 2.0/3.0</li>
                <li>Navionics</li>
                <li>C-MAP MAX</li>
            </ul>
            <p>W Transmission Power–increases the range and AlS 5</p>
            <p>AlS Satellite reception enabling Global tracking</p>
            <p>Satellite reception enabling Global tracking</p>
            <p>Increased Transmission Rate (Based on speed)</p>
            <p>exactTrax™️: exactTrax Tracking</p>
        </div>}
    </div>
    {modal && <ImageModal modal={modal} setModal={setModal} image={selectedImage} />}
</div>
}
export default Category;