// import Logo from "../../../assets/images/logo/logo.png";
function FooterBottom() {
	return (
		<>
			{/* <div className="col-lg-6">
				<div className="aximo-footer-logo">
					<a href="">
						<img src={Logo} alt="Logo" width={80} />
					</a>
				</div>
			</div> */}
			<div className="col-lg-12">
				<div className="aximo-copywright one">
					<p> &copy; Copyright 2024, Power by <a href='https://lionmediakw.com' target="_blank" rel="noreferrer">lionmediakw</a></p>
				</div>
			</div>
		</>
	);
}

export default FooterBottom;
