import { Button, Modal } from "react-bootstrap"

const ImageModal = ({modal, setModal, image}) => {
    return <Modal className="fade" show={modal} onHide={setModal}>
        {/* <Modal.Header> */}
            <Button
                variant=""
                style={{
                    position: 'absolute',
                    color: '#000',
                    fontSize: '25px',
                    right: '0',
                    borderRadius: '50%',
                }}
                className="close"
                onClick={()=> setModal(false)}
                >
                <span>&times;</span>
            </Button>
        {/* </Modal.Header> */}
        <div>
            <img src={image} alt='category' className="w-100" />
        </div>
    </Modal>
}
export default ImageModal